"use client";

import { Button } from "@/components/ui/button";
import "./globals.css";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <html>
      <body className="flex flex-col flex-1 min-h-screen items-center justify-center">
        <div>
          <h2 className="text-2xl font-semibold">
            Ops! Something went wrong! 🥲
          </h2>

          <p className="text-slate-400 text-sm text-center">
            Please retry later, if the issue persist!
          </p>

          <Button className="mt-6" variant={"primary"} onClick={() => reset()}>
            Try again
          </Button>
        </div>
      </body>
    </html>
  );
}
